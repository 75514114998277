var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "box",
    _vm._l(_vm.columns, function (column) {
      return _c("box-column", {
        key: column,
        attrs: { title: column },
        scopedSlots: _vm._u(
          [
            {
              key: "value",
              fn: function () {
                return [_c("line-loader", { staticClass: "rounded" })]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }