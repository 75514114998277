<template>
  <box>
    <box-column v-for="column in columns" :key="column" :title="column">
      <template #value>
        <line-loader class="rounded" />
      </template>
    </box-column>
  </box>
</template>

<script>
import Box from '@/components/atoms/Box/Box';
import BoxColumn from '@/components/atoms/Box/BoxColumn';
import LineLoader from '@/components/atoms/Loaders/LineLoader';

export default {
  name: 'AnalyticsTotalLoader',
  components: { BoxColumn, Box, LineLoader },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped></style>
