import { INGESTION_JOB_NOTIFICATIONS_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 * @param {import('@/services/api/Obelix').Obelix} api
 * @param {string} clientId
 * @param {QueryParamsBuilder} params
 * @returns {Promise<{data:string}>}
 */
export default function (api, clientId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl({
    [INGESTION_JOB_NOTIFICATIONS_RESOURCE]: null,
  });
  params.addFilter('client.id', clientId);

  return api.get(partialUrl, params.buildWithoutPage());
}
